<template>
  <div class="home-container">
    <PageLayout>
      <template #page-name>
        <div class="nav-title font-menu-large">
          <div
            class="nav-title-item"
            v-for="(item, index) in navList"
            :key="item.id"
            :class="{ active: item.id === currentPage }"
            @click="changePage(index)"
          >
            {{ item.title }}
          </div>
        </div>
      </template>
      <template #page-content>
        <div
          class="course-content-book"
          v-if="!hasComingClass && currentPage === 0"
        >
          <div class="content-book">
            <img src="@/assets/img/01-Menu/Book.svg" alt="" />
            <span class="text-one font-menu-small"
              >You don't have any scheduled classes yet.</span
            >
            <span class="text-two font-menu-small">Book your next class!</span>
            <span class="text-button" @click.stop="bookButton"
              >Book a Class</span
            >
          </div>
        </div>
        <div class="course-content" v-if="hasComingClass">
          <!-- <router-view></router-view> -->
          <template v-for="(item, index) in currentClassListInfo">
            <ClassBox
              :courseClassify="item.courseStatus"
              :classListInfo="item"
              :key="index"
            />
          </template>
          <div
            class="pagination"
            v-if="currentPage !== 0 && currentClassListInfo.length"
          >
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPageIndex"
              :page-sizes="[3, 5, 10]"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="totalPage"
              background
            >
            </el-pagination>
          </div>
        </div>
      </template>
    </PageLayout>
  </div>
</template>

<script>
// api
import {
  getKidsCourseData,
  getKidsCourseDataByPage,
  getKidsCancelledCourseByPage,
  changeCurrentKid
} from '@/assets/js/axios/kidsApi'
import PageLayout from '@/components/Page/Layout/PageLayout.vue'
import ClassBox from '@/components/Page/ClassBox'
import { getFormatDate } from '@/utils/tools'

export default {
  name: 'Home',
  components: {
    PageLayout,
    ClassBox
  },
  data() {
    return {
      currentPage: 0,
      totalPage: null,
      currentPageIndex: 1, // 跳页
      pageSize: 5,
      navList: [
        {
          title: 'Upcoming classes',
          id: 0
        },
        {
          title: 'Finished',
          id: 1
        },
        {
          title: 'Cancelled',
          id: 2
        },
        {
          title: 'Missed',
          id: 3
        }
      ],
      courseList: [],
      currentClassListInfo: [],
      rangeNum: 30, // 查看课的天数
      userId: null,
      hasComingClass: true
    }
  },
  async mounted() {
    if (!this.$store.state.dashboard.isKid) {
      const sendData = {
        id: this.$store.state.dashboard.kidUserId
      }
      const result = await changeCurrentKid(sendData)
      // console.log(result);
      if (result && result.code === 200) {
        localStorage.userData = JSON.stringify(result.data)
        this.$store.commit('setUserInfo', result.data)
        // this.$router.push({ name: "Home" });
      }
    }
    await this.getUpcomingClass()
  },
  methods: {
    // 跳转自主选课 book
    bookButton() {
      this.$router.push({
        path: '/dashboard/bookclass'
      })
    },
    handleCurrentChange(value) {
      // console.log(value);
      this.currentPageIndex = value
      if (this.currentPage === 1) {
        this.getFinishedClass()
      } else if (this.currentPage === 2) {
        this.getCancelledClass()
      } else {
        this.getMissedClass()
      }
    },
    handleSizeChange(value) {
      // console.log(value);
      this.pageSize = value
      if (this.currentPage === 1) {
        this.getFinishedClass()
      } else if (this.currentPage === 2) {
        this.getCancelledClass()
      } else {
        this.getMissedClass()
      }
    },
    getUserCourse(data) {
      this.currentClassListInfo = data.map((item, sort) => {
        // 遍历 contentObjList 中的每个元素并处理 learningGoals
        if (item.contentObjList) {
          item.contentObjList.forEach((content) => {
            const learningGoalsList = [] // 存储每个 syllabus 的学习目标数据

            // 获取每个 content 中的 learningGoals
            if (content.learningGoals) {
              const parsedGoals = JSON.parse(content.learningGoals)
              const goalsMap = {}

              parsedGoals.forEach((goal) => {
                goalsMap[goal.fieldName] = goal.value
              })

              if (goalsMap.syllabusList) {
                goalsMap.syllabusList.forEach((syllabus) => {
                  const summaryData = {
                    syllabusTitle: '', // 初始化 syllabusTitle
                    content: [],
                    imgList: []
                  }

                  syllabus.forEach((ele) => {
                    if (ele.fieldName === 'syllabusTitle') {
                      summaryData.syllabusTitle = ele.value // 设置 syllabusTitle
                    }
                    if (ele.fieldName === 'syllabusContentList') {
                      ele.value.forEach((contentItem) => {
                        contentItem.forEach((subItem) => {
                          if (subItem.type === 7 && subItem.options) {
                            // 处理文字内容
                            const contentArray = subItem.options.map(
                              (option) => ({
                                zh: option.wordZh,
                                pinyin: option.wordPy
                              })
                            )
                            summaryData.content.push(...contentArray)
                          } else if (subItem.type === 8 && subItem.value) {
                            // 处理图片内容
                            summaryData.imgList.push(...subItem.value)
                          }
                        })
                      })
                    }
                  })

                  learningGoalsList.push(summaryData) // 将每个 syllabus 的数据添加到 learningGoalsList 中
                })
              }
            }

            // 替换 content 中的 learningGoals 为处理后的 learningGoalsList
            content.learningGoals = learningGoalsList
          })
        }

        return {
          startTime: this.getFormDate(item.eventStarttime),
          classTime: item.eventStarttime,
          classType: item.classType === 1 ? '1-on-1 Online Class • 25 Min' : '',
          teacherName:
            item.teacherName != null
              ? item.teacherPyName != null
                ? `${item.teacherName} | ${item.teacherPyName}`
                : item.teacherName
              : item.teacherPyName || '',
          teacherAvatar: item.teacherAvatar,
          levelNo: item.kidsLevelNo,
          chapterNo: item.kidsChapterNo,
          lessonId: item.kidsLessonId,
          lessonNo: item.kidsLessonNo,
          lessonCoverImg: item.lessonPic,
          chapterTitle: item.chapterTitle,
          chapterTitleEn: item.chapterTitleEn,
          lessonTitleHanzi: item.lessonTitle,
          lessonTitlePinyin: item.lessonTitlePy,
          lessonTitleEnglish: item.lessonTitleEn,
          contentObjList: item.contentObjList, // 替换后的 contentObjList
          demo: item.isTrial ? 'Demo Class 25Min' : '',
          courseStatus: this.currentPage,
          calendarEventId: item.id,
          userId: item.userId,
          userAvatar: item.userAvatar,
          userName: item.username,
          teacherId: item.teacherId,
          endTime: item.endTime
        }
      })
    },

    getFormDate(date) {
      const dateArr = String(new Date(date)).split(' ')
      return (
        dateArr[0] +
        ', ' +
        dateArr[1] +
        ' ' +
        dateArr[2] +
        ' at ' +
        date.split('T')[1].split('.')[0].split(':')[0] +
        ':' +
        date.split('T')[1].split('.')[0].split(':')[1]
      )
    },
    changePage(index) {
      this.hasComingClass = true
      this.currentClassListInfo = []
      this.currentPage = index
      this.currentPageIndex = 1
      this.pageSize = 5
      if (index === 0) {
        this.getUpcomingClass()
      } else if (index === 1) {
        this.getFinishedClass()
      } else if (index === 2) {
        this.getCancelledClass()
      } else {
        this.getMissedClass()
      }
    },
    async getUpcomingClass() {
      const eventStarttime =
        getFormatDate(new Date()).split(' ')[0] + ' 00:00:00'
      const nextDay = new Date(
        new Date().setDate(new Date().getDate() + this.rangeNum)
      )
      const eventEndtime = getFormatDate(nextDay).split(' ')[0] + ' 00:00:00'
      const sendData = {
        eventStarttime,
        eventEndtime
      }
      const { data } = await getKidsCourseData(sendData)
      console.log('UpcomingClass:', data)
      if (data.length === 0) {
        this.hasComingClass = false
      } else {
        this.hasComingClass = true
        this.calendarEventId = data.length ? data[0].id : ''
        // console.log(this.calendarEventId);
        this.teacherAvatar = data.length ? data[0].teacherAvatar : ''
        this.$store.commit('setTeacherAvatar', this.teacherAvatar)
        this.getUserCourse(data)
      }
    },
    async getFinishedClass() {
      const sendData = {
        eventState: 4,
        page: {
          current: this.currentPageIndex,
          size: this.pageSize
        },
        isMissing: false
        // userId: this.userId,
      }
      const { data } = await getKidsCourseDataByPage(sendData)
      this.getUserCourse(data.records)
      console.log('FinishClass:', data.records)
      this.totalPage = data.total
    },
    async getCancelledClass() {
      const sendData = {
        eventState: 5,
        page: {
          current: this.currentPageIndex,
          size: this.pageSize
        }
      }
      const { data } = await getKidsCancelledCourseByPage(sendData)
      this.getUserCourse(data.records)
      console.log('Cancelled:', data.records)
      this.totalPage = data.total
    },
    async getMissedClass() {
      const sendData = {
        eventState: 4,
        isMissing: true,
        page: {
          current: this.currentPageIndex,
          size: this.pageSize
        }
      }
      const { data } = await getKidsCancelledCourseByPage(sendData)
      this.getUserCourse(data.records)
      console.log('Missed:', data.records)
      this.totalPage = data.total
    }
  }
}
</script>

<style lang="scss" scoped>
// @import "@/views/Client/Home/index.scss";
.home-container {
  // margin-top: 2%;
  // background: chocolate;
  overflow: auto;

  .nav-title {
    display: flex;

    .nav-title-item {
      cursor: pointer;
      margin-right: 5%;
      position: relative;
      color: #9e9e9f;
      font-weight: 600;

      &:hover {
        color: #224e96;
      }

      &.active {
        color: #224e96;
        &::after {
          position: absolute;
          content: '';
          animation: stretchAnimation 0.3s;
          background-color: #f4d737;
          height: 7px;

          left: 0;
          width: 60px;
          bottom: -12px;
        }
      }
    }
  }
  .course-content {
    width: 100%;
    // height: 100%;
    height: 75vh;
    // height: 100%;
    // background: #fff;
    // border-radius: 20px;
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/ .number.active {
        background-color: #224e96;
      }
      /deep/ .el-page li:not(.disabled):hover {
        color: #224e96;
      }
    }
  }
  .course-content-book {
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .content-book {
      width: 40%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
      }
      span {
        color: #224e96;
        font-size: 14px;
        font-weight: 600;
      }
      .text-one {
        margin-top: 25px;
      }
      .text-two {
        margin-bottom: 25px;
      }
      .text-button {
        padding: 8px 20px;
        border-radius: 20px;
        background-color: #cd4c3f;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
      }
      .text-button:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
